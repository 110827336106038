import React, {Component} from 'react';
import '../App.css';
import kali from 'kali';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const googleAuthProtocol = process.env.REACT_APP_RAVEN_GOOGLE_AUTH_PROTOCOL;
const googleAuthHost = process.env.REACT_APP_RAVEN_GOOGLE_AUTH_HOST;
const office365AuthProtocol = process.env.REACT_APP_RAVEN_OFFICE_365_AUTH_PROTOCOL;
const office365AuthHost = process.env.REACT_APP_RAVEN_OFFICE_365_AUTH_HOST;

class CalendarDropDown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			calendarList:  [],
			calendarNames: [],
			invalidEmail:  false,
			sharedEmail:   ''
		};
	}

    componentDidMount = () => {
    	this.handleCalendarList();
    }

	handleInputChange = (e) => {
		const target = e.target;
		let value;
		switch (target.type) {
		case 'text':
			value = target.value;
			break;
		default:
			console.error('invalid input type');
		}

		const name = target.name;

		this.setState({
			[name]: value
		});
	}

    handleCalendarList = (defaultEmail) => {
		let url;
    	switch (this.props.provider) {
    	case 'google':
			url = `${googleAuthProtocol}://${googleAuthHost}/GoogleCalendarList`;
    		break;
    	case 'office365':
    		url = `${office365AuthProtocol}://${office365AuthHost}/Office365CalendarList`;
    		break;
    	default:
    		console.error('invalid provider');
    	}

    	let id = this.props.id;

    	let email = this.state.sharedEmail;
    	if (defaultEmail) {
    		email = '';
    	}

    	let msgBody = {
    		id:    id,
    		email: email
    	};

    	new kali({
    		body:    msgBody,
    		headers: {'content_type': 'application/json'},
    		method:  'POST'
    	}).post(url, {
    		success: (_kali, res, contents) => {
    			console.log(contents);
    			if (!contents.items) {
    				contents.items = [ {name: 'Invalid email address or permissions'} ];
    				this.setState({
    					calendarList: contents.items,
    					invalidEmail: true
    				});
    				return this.handleCalendarListDropDown();
    			}


    			this.setState({
    				calendarList: contents.items,
    				invalidEmail: false
    			});
    			return this.handleCalendarListDropDown();
    		},

    		failure: (_kali, err) => {
    			console.log(err);
    			this.setState({errMsg: err.statusText});
    		}
    	});
    }

    handleCalendarListDropDown = () => {
    	let calendarList = this.state.calendarList;
    	let calendarNames = [];
    	calendarList.forEach((entry) => {
    		calendarNames.push(<option key={entry.name}>{entry.name}</option>);
    	});
    	this.setState({calendarNames: calendarNames});
    	return (
    		<select>
    			{calendarNames}
    		</select>
    	);
    }

    handleCalendarIDFormSubmit = (e) => {
    	e.preventDefault();

		let url;
    	switch (this.props.provider) {
    	case 'google':
    		url = `${googleAuthProtocol}://${googleAuthHost}/GoogleCalendarID`;
    		break;
    	case 'office365':
    		url = `${office365AuthProtocol}://${office365AuthHost}/Office365CalendarID`;
    		break;
    	default:
    		console.error('invalid provider');
    	}

    	let calendar_name = document.getElementById('calendar_dropdown').value;
    	let calendar_id;

    	this.state.calendarList.forEach((calendar) => {
    		if (calendar.name === calendar_name) {
    			calendar_id = calendar.id;
    		}
    	});

    	let config_id = this.props.id;

    	new kali({
    		body: {
    			config_id:    config_id,
    			calendar_id:  calendar_id,
    			backend_name: calendar_name,
    			shared_email: this.state.sharedEmail
    		},
    		headers: {'content_type': 'application/json'},
    		method:  'POST'
    	}).post(url, {
    		success: (_kali, res, contents) => {
    			toast.success('Success', {
    				position: toast.POSITION.TOP_RIGHT
    			});
    		},

    		failure: (_kali, err) => {
    			toast.error(err.statusText, {
    				position: toast.POSITION.TOP_RIGHT
    			});
    		},

    		serverError: (_kali, res, err) => {
    			console.warn(err);
    			toast.error(err, {
    				position: toast.POSITION.TOP_RIGHT
    			});
    		}
    	});
    }

    render() {
    	return (
    		<div className="crow-form-field">
    			<ToastContainer autoClose={3000} />

    			<h3>Select the Calendar Name You Wish To Associate with Your Account</h3>
    			<form className="crow-form" id="crow-form-form_1" onSubmit={this.handleCalendarIDFormSubmit}>
    				<select id="calendar_dropdown">
    					{this.state.calendarNames}
    				</select>
    				<button type="submit" className="btn" disabled={this.state.invalidEmail}>Submit</button>
    			</form>
    			<form className="crow-form" id="crow-form-form_2" onSubmit={(e) => {
    				e.preventDefault(); this.handleCalendarList();
    			}}>
    				<div className="crow-form-field">
    					<div className="crow-field">
    						<input type="text" name="sharedEmail" onChange={this.handleInputChange} value={this.state.sharedEmail} />
    					</div>
    				</div>
    				<button type="submit" className="btn">Use a Shared Email</button>
    			</form>

    			{/* In a crow-form div only for button styling */}
    			<div className="crow-form">
    				<button className="btn" onClick={(e) => {
    					e.preventDefault();
    					this.setState({sharedEmail: ''});
    					this.handleCalendarList('default');
    				}}>Use Default Email</button>
    			</div>
    		</div>
    	);
    }
}

export default CalendarDropDown;