/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import './App.css';
import kali from 'kali';

import CalendarDropDown from './component/CalendarList';
import Settings from './component/Settings';

import {
	BrowserRouter as Router,
	Switch,
	Redirect,
	Route
} from 'react-router-dom';

const googleAuthProtocol = process.env.REACT_APP_RAVEN_GOOGLE_AUTH_PROTOCOL;
const googleAuthHost = process.env.REACT_APP_RAVEN_GOOGLE_AUTH_HOST;
const office365AuthProtocol = process.env.REACT_APP_RAVEN_OFFICE_365_AUTH_PROTOCOL;
const office365AuthHost = process.env.REACT_APP_RAVEN_OFFICE_365_AUTH_HOST;

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			calendar_list: []
		};
	}

	handleLogin = (provider) => {
		console.log(provider);

		let emailInput = document.getElementById('email-input').value;

		let url;
		switch (provider) {
		case 'google':
			url = `${googleAuthProtocol}://${googleAuthHost}/GoogleLogin/?email=${emailInput}`;
			break;
		case 'outlook':
			url = `${office365AuthProtocol}://${office365AuthHost}/Office365Login/?email=${emailInput}`;
			break;
		default:
			url = '';
			break;
		}

		if (url === '') {
			console.error('no provider selected');
			return;
		}

		new kali().get(url, {
			success: (_kali, res, contents) => {
				console.log(contents);
				window.location.href = contents;
			},

			failure: (_kali, err) => {
				console.error(err);
			}
		});
	}

	render() {
		return (
			<Router>
				<Switch>
					{/* <Route exact path="/">
						<Redirect to="/login"/>
					</Route> */}
					<Route exact path="/"
						render={() => {
							return (
								<div className="crow-form-field">
									<div className="crow-field">
										<label htmlFor="email-input">Email:</label>
										<input type="text" id="email-input" name="email-input" />
									</div>
									<button onClick={(e) => {
										e.preventDefault(); this.handleLogin('google');
									}}>Login with Google</button>
									<button onClick={(e) => {
										e.preventDefault(); this.handleLogin('outlook');
									}}>Login with Outlook</button>
									<p className="login-warning">If you are currently logged in with a provider, your account will be associated with that login information.</p>
								</div>
							);
						}}
					/>

					<Route exact path="/calendar_list/:provider/:id"
						render={({match}) => {
							return (
								<CalendarDropDown
									id={match.params.id}
									provider={match.params.provider}
								/>
							);
						}}
					/>

					<Route exact path="/settings/:id"
						render={(id) => {
							if (!id || !id.match || !id.match.params) {
								return (
									<Redirect to="/login" />
								);
							}

							let userID = id.match.params.id;

							if (userID === 'new') {
								userID = '';
							}

							return (
								<Settings
									userID={userID}
								/>
							);
						}}
					/>
				</Switch>
			</Router>
		);
	}
}

export default App;
