/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import '../App.css';
import kali from 'kali';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const jsonHeader = {'content_type': 'application/json'};

const slug = process.env.REACT_APP_RAVEN_LOGIN_UI_SLUG;
const uuid = process.env.REACT_APP_RAVEN_LOGIN_UI_UUID;

const broncoConfigHost = process.env.REACT_APP_BRONCO_HOST;
const broncoConfigProtocol = process.env.REACT_APP_BRONCO_PROTOCOL;

let componentSettings = {};

const office365AuthProtocol = process.env.REACT_APP_RAVEN_OFFICE_365_AUTH_PROTOCOL;
const office365AuthHost = process.env.REACT_APP_RAVEN_OFFICE_365_AUTH_HOST;

class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			raven_calendar_name:       '',
			raven_private_event_label: '',
			raven_client_branding:     false,
			raven_days:                [1, 2, 3, 4, 5],
			raven_day_count:           1,
			raven_display_organizer:   false,
			raven_time_format:         '12_hour',
			raven_hour_start:          8,
			raven_hour_end:            18,
			raven_minute_start:        0,
			raven_minute_end:          0,
			raven_event_count:         4,
			raven_orientation:         0,
			raven_scroll_events:       false,
			raven_show_event_date:     false,
			raven_show_room_name:      false,
			raven_time_period:         false,
			time_zone:                 'America/New_York',
			time_zone_list:	           [],
			raven_theme:               'Ocean',
			raven_ts_branding:         true,
			raven_ts_branding_color:   'WhiteBlue'
		};
	}

    componentDidMount = () => {
    	this.handleBroncoSettings();

    	let validTimeZones = [
    		{
    			'name': 'America/Los_Angeles'
    		},
    		{
    			'name': 'America/Denver'
    		},
    		{
    			'name': 'America/Chicago'
    		},
    		{
    			'name': 'America/New_York'
    		},
    		{
    			'name': 'America/Phoenix'
    		},
    		{
    			'name': 'America/Anchorage'
    		},
    		{
    			'name': 'Pacific/Honolulu'
    		},
    		{
    			'name': 'America/Regina' // Saskatchewan
    		},
    		{
    			'name': 'Atlantic/Bermuda'
    		},
    		{
    			'name': 'America/St_Johns' // Newfoundland
    		},
    		{
    			'name': 'Australia/Brisbane'
    		},
    		{
    			'name': 'Pacific/Guam'
    		}
    	];
    	let htmlTimeZones = [];

    	validTimeZones.forEach((zone) => {
    		htmlTimeZones.push(<option key={zone.name} value={zone.name}>{zone.name}</option>);
    	});

    	this.setState({time_zone_list: htmlTimeZones});
    }

	handleInputChange = (e) => {
		const target = e.target;
		let value;
		switch (target.type) {
		case 'text':
			value = target.value;
			break;
		case 'number':
			value = Number(target.value);
			break;
		case 'checkbox':
			value = target.checked;
			break;
		default:
			let errMsg = 'Invalid target.type in handleInputChange';
            return this.concurrentErrorHandler(errMsg);
        }

		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleOptionChange = (e) => {
		const name = e.target.name;
		this.setState({
			[name]: e.target.value
		});
	}

	handleDayPicker = (e) => {
		let daysArr = this.state.raven_days;
		let dayFound = false;
		let value = Number(e.target.value);

		daysArr.forEach((day, i) => {
			if (day === value) {
				daysArr.splice(i, 1);
				dayFound = true;
			}
		}, dayFound);

		if (!dayFound) {
			daysArr.push(value);
		}

		daysArr.sort();

		this.setState({
			raven_days: daysArr
		});
	}

	handleDisplayFormSubmit = (userID) => {
		let settings = {
			raven_calendar_name:       this.state.raven_calendar_name,
			raven_private_event_label: this.state.raven_private_event_label,
			raven_client_branding:     this.state.raven_client_branding,
			raven_days:                this.state.raven_days,
			raven_day_count:           this.state.raven_day_count,
			raven_display_organizer:   this.state.raven_display_organizer,
			raven_time_format:         this.state.raven_time_format,
			raven_hour_start:          this.state.raven_hour_start,
			raven_hour_end:            this.state.raven_hour_end,
			raven_minute_start:        this.state.raven_minute_start,
			raven_minute_end:          this.state.raven_minute_end,
			raven_event_count:         this.state.raven_event_count,
			raven_orientation:         parseInt(this.state.raven_orientation),
			raven_scroll_events:	   this.state.raven_scroll_events,
			raven_show_event_date:     this.state.raven_show_event_date,
			raven_show_room_name:      this.state.raven_show_room_name,
			raven_time_period:         this.state.raven_time_period,
			raven_theme:               this.state.raven_theme,
			raven_ts_branding:         this.state.raven_ts_branding,
			raven_ts_branding_color:   this.state.raven_ts_branding_color,
			time_zone:                 this.state.time_zone,
			user_id:                   userID
		};
		console.log(settings);

		new kali({
			body:    settings,
			headers: {'content_type': 'application/json'},
			method:  'POST'
		}).post(`${office365AuthProtocol}://${office365AuthHost}/Office365Settings`, {
			success: (_kali, res, contents) => {
				toast.success('Success', {
    				position: toast.POSITION.TOP_RIGHT
    			});
			},

			failure: (_kali, err) => {
				toast.error(err.statusText, {
    				position: toast.POSITION.TOP_RIGHT
    			});
			}
		});
	}

    handleBroncoSettings = () => {
    	if (this.props.userID === '') {
    		return;
    	}

    	let body = JSON.stringify({});
    	let targetURL = `${broncoConfigProtocol}://${broncoConfigHost}/r/view/maps/component/${this.props.userID}`;
    	new kali({
    		body:    body,
    		headers: jsonHeader,
    		method:  'POST'
    	}).post(targetURL, {
    		success: (_kali, res, contents) => {
    			let {data} = contents;
    			if (!data) {
    				let errMsg = 'No data property in Bronco Settings Response';
    				return this.concurrentErrorHandler(errMsg);
    			}

    			let {setting_list} = data;
    			if (!setting_list || setting_list.length < 1) {
    				let errMsg = 'No setting list property in Bronco Settings Response';
    				return this.concurrentErrorHandler(errMsg);
    			}

    			setting_list.map(this.setBroncoSetting);

    			console.log('COMPONENT SETTINGS:');
    			console.log(componentSettings);

    			let newState = {};

    			let keys = Object.keys(componentSettings);
    			for (let i = 0, l = keys.length; i < l; i++) {
    				newState[keys[i]] = componentSettings[keys[i]];
    			}

    			this.setState(newState);

    		},

    		failure: (_kali, err) => {
    			let errMsg = `Err in Bronco Settings network request: ${err}`;

    			return this.concurrentErrorHandler(errMsg);
    		}
    	});
    }

    setBroncoSetting = (setting) => {
    	if (!setting || !setting.setting || !setting.setting.name) {
    		console.error('Bad setting heard in setBroncoSetting:');
    		console.error(setting);
    		return;
    	}

    	let nextValue;

    	switch (setting.setting.name) {
    	case 'raven_event_count':
    	case 'raven_day_count':
    	case 'raven_hour_start':
    	case 'raven_hour_end':
    	case 'raven_minute_start':
		case 'raven_minute_end':
		case 'raven_orientation': {
    		nextValue = setting.int;
    		if (isNaN(nextValue)) {
    			console.error('Cannot find .int in setting in setBroncoSetting:');
    			console.error(setting);
    			return;
    		}

    		break;
    	}

    	case 'raven_days': {
    		nextValue = setting.json;
    		try {
    			nextValue = JSON.parse(nextValue);
    		} catch (err) {
    			console.error('Failed to parse JSON in setBroncoSetting:');
    			console.error(setting);
    			console.error('err:');
    			console.error(err);
    			return;
    		}

    		break;
    	}

    	case 'raven_ts_branding':
    	case 'raven_client_branding':
    	case 'raven_display_organizer':
		case 'raven_scroll_events':
    	case 'raven_show_event_date':
    	case 'raven_show_room_name':
    	case 'raven_time_period': {
    		nextValue = setting.bool;
    		if (nextValue !== true && nextValue !== false) {
    			console.error('Cannot find .bool in setting in setBroncoSetting:');
    			console.error(setting);
    			return;
    		}

    		break;
    	}

    	default: {
    		/* This catches:
					raven_private_event_label
					raven_calendar_name
					raven_theme
					raven_time_format
					raven_ts_branding_color
				*/

    		nextValue = setting.string;
    		if (!nextValue && nextValue !== '') {
    			console.error('Cannot find .string in setting in setBroncoSetting:');
    			console.error(setting);
    			return;
    		}

    	}
    	}

    	componentSettings[setting.setting.name] = nextValue;
    }

    concurrentErrorHandler = (errMsg) => {
    	console.error(`Error heard in other process:`);
    	console.error(errMsg);
    }

    render() {
    	return (
    		<div id="root" >
    			<ToastContainer autoClose={3000} />

    			<form className="crow-form" id="crow-form-form_1" onSubmit={(e) => {
    				e.preventDefault(); this.handleDisplayFormSubmit(this.props.userID);
    			}}>
    				<div>
    					<div className="crow-form-page">
    						<h1>Bronco &gt; Raven</h1>
    						<div className="crow-form-group">
    							<div>
    								<h2>Display Settings</h2>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Calendar Name</label>
    										<input name="raven_calendar_name" type="text" placeholder="Calendar Name" onChange={this.handleInputChange} value={this.state.raven_calendar_name} />
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Private Event Label</label>
    										<input name="raven_private_event_label" type="text" placeholder="Private Event" onChange={this.handleInputChange} value={this.state.raven_private_event_label} />
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Time Format</label>
    										<div className="customSwitch">
    											<div>
    												<input type="radio" id="12hour" name="raven_time_format" value="12_hour" onChange={this.handleOptionChange} checked={this.state.raven_time_format === '12_hour'} />
    												<label htmlFor="12hour"><span>12-Hour</span></label>
    											</div>
    											<div>
    												<input type="radio" id="24hour" name="raven_time_format" value="24_hour" onChange={this.handleOptionChange} checked={this.state.raven_time_format === '24_hour'} />
    												<label htmlFor="24hour"><span>24-Hours</span></label>
    											</div>
    										</div>
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Hour Start</label>
    										<input name="raven_hour_start" type="number" min="0" max="23" onChange={this.handleInputChange} value={this.state.raven_hour_start} />
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Minute Start</label>
    										<input name="raven_minute_start" type="number" min="0" max="59" onChange={this.handleInputChange} value={this.state.raven_minute_start} />
											<span className="not-in-use">Component not connected to the FE yet.</span>
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Hour End</label>
    										<input name="raven_hour_end" type="number" min="0" max="23" onChange={this.handleInputChange} value={this.state.raven_hour_end} />
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Minute End</label>
    										<input name="raven_minute_end" type="number" min="0" max="59" onChange={this.handleInputChange} value={this.state.raven_minute_end} />
											<span className="not-in-use">Component not connected to the FE yet.</span>
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Show Time Period</label>
    										<input className="on-off" name="raven_time_period" type="checkbox" checked={this.state.raven_time_period} onChange={this.handleInputChange} value={this.state.raven_time_period} />
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Time Zone</label>
    										<select name="time_zone" onChange={this.handleOptionChange} value={this.state.time_zone}>
    											{this.state.time_zone_list}
    										</select>
											<span className="not-in-use">Component not connected to the FE yet. Is connected to BE to get events.</span>
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Orientation</label>
    										<select name="raven_orientation" onChange={this.handleOptionChange} value={this.state.raven_orientation}>
												<option key='0'>0</option>
												<option key='90'>90</option>
												<option key='180'>180</option>
												<option key='270'>270</option>
    										</select>
											<span className="not-in-use">Component not connected to the FE yet.</span>
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Display Organizer</label>
    										<input className="on-off" name="raven_display_organizer" type="checkbox" checked={this.state.raven_display_organizer} onChange={this.handleInputChange} value={this.state.raven_display_organizer} />
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Show Event Date</label>
    										<input className="on-off" name="raven_show_event_date" type="checkbox" checked={this.state.raven_show_event_date} onChange={this.handleInputChange} value={this.state.raven_show_event_date} />
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Event Count</label>
    										<input name="raven_event_count" type="number" onChange={this.handleInputChange} value={this.state.raven_event_count} />
											<span className="not-in-use">Cannot be more than 4.</span>
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Theme</label>
    										<ul className="themepicker">
    											<li><input type="radio" id="themepicker_1" name="raven_theme" value="Ocean" onChange={this.handleOptionChange} checked={this.state.raven_theme === 'Ocean'} /><label htmlFor="themepicker_1">Ocean</label></li>
    											<li><input type="radio" id="themepicker_2" name="raven_theme" value="Mountains" onChange={this.handleOptionChange} checked={this.state.raven_theme === 'Mountains'} /><label htmlFor="themepicker_2">Mountains</label></li>
    											<li><input type="radio" id="themepicker_3" name="raven_theme" value="Desert" onChange={this.handleOptionChange} checked={this.state.raven_theme === 'Desert'} /><label htmlFor="themepicker_3">Desert</label></li>
    											<li><input type="radio" id="themepicker_4" name="raven_theme" value="Forest" onChange={this.handleOptionChange} checked={this.state.raven_theme === 'Forest'} /><label htmlFor="themepicker_4">Forest</label></li>
    										</ul>
    									</div>
    								</div>
    								{/* <div className="crow-form-field">
    									<div className="crow-field">
    										<label>Days of the Week</label>
    										<input className="on-off" type="checkbox" id="daypicker_1" name="Sunday" value="0" onChange={this.handleDayPicker} checked={this.state.raven_days.includes(0)} /><label htmlFor="daypicker_1">Sunday</label>
    										<input className="on-off" type="checkbox" id="daypicker_2" name="Monday" value="1" onChange={this.handleDayPicker} checked={this.state.raven_days.includes(1)} /><label htmlFor="daypicker_2">Monday</label>
    										<input className="on-off" type="checkbox" id="daypicker_3" name="Tuesday" value="2" onChange={this.handleDayPicker} checked={this.state.raven_days.includes(2)} /><label htmlFor="daypicker_3">Tuesday</label>
    										<input className="on-off" type="checkbox" id="daypicker_4" name="Wednesday" value="3" onChange={this.handleDayPicker} checked={this.state.raven_days.includes(3)} /><label htmlFor="daypicker_4">Wednesday</label>
    										<input className="on-off" type="checkbox" id="daypicker_5" name="Thursday" value="4" onChange={this.handleDayPicker} checked={this.state.raven_days.includes(4)} /><label htmlFor="daypicker_5">Thursday</label>
    										<input className="on-off" type="checkbox" id="daypicker_6" name="Friday" value="5" onChange={this.handleDayPicker} checked={this.state.raven_days.includes(5)} /><label htmlFor="daypicker_6">Friday</label>
    										<input className="on-off" type="checkbox" id="daypicker_7" name="Saturday" value="6" onChange={this.handleDayPicker} checked={this.state.raven_days.includes(6)} /><label htmlFor="daypicker_7">Saturday</label>
    									</div>
    								</div> */}
    							</div>
    						</div>
    						<div className="crow-form-group">
    							<div>
    								<h2>Admin Settings</h2>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Days To Return</label>
    										<input name="raven_day_count" type="number" onChange={this.handleInputChange} value={this.state.raven_day_count} />
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>TouchSource Branding</label>
    										<input className="on-off" name="raven_ts_branding" type="checkbox" checked={this.state.raven_ts_branding} onChange={this.handleInputChange} value={this.state.raven_ts_branding} />
    									</div>
    								</div>
    								{this.state.raven_ts_branding ?
    									<div className="crow-form-field">
    										<div className="crow-field">
    											<label>TouchSource Branding Color</label>
    											<select name="raven_ts_branding_color" onChange={this.handleOptionChange} value={this.state.raven_ts_branding_color}>
    												<option key='White'>White</option>
    												<option key='Black'>Black</option>
    												<option key='BlackBlue'>BlackBlue</option>
    												<option key='WhiteBlue'>WhiteBlue</option>
    											</select>
    										</div>
    									</div> :
    									''
    								}
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Client Branding</label>
    										<input className="on-off" name="raven_client_branding" type="checkbox" checked={this.state.raven_client_branding} onChange={this.handleInputChange} value={this.state.client_branding} />
											<span className="not-in-use">Component not connected to the FE yet.</span>
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Show Room Name Per Event</label>
    										<input className="on-off" name="raven_show_room_name" type="checkbox" checked={this.state.raven_show_room_name} onChange={this.handleInputChange} value={this.state.raven_show_room_name} />
											<span className="not-in-use">Component not connected to the FE yet.</span>
    									</div>
    								</div>
    								<div className="crow-form-field">
    									<div className="crow-field">
    										<label>Scroll Events</label>
    										<input className="on-off" name="raven_scroll_events" type="checkbox" checked={this.state.raven_scroll_events} onChange={this.handleInputChange} value={this.state.raven_scroll_events} />
    									</div>
    								</div>
    							</div>
    						</div>
    					</div>
    					<div className="btn-container">
    						<button type="submit" className="btn">Create</button>
    					</div>
    				</div>
    			</form>
    		</div>
    	);
    }
}

export default Settings;
